import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classnames from 'classnames';

import UniversalImage from 'components/common/UniversalImage';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { IFacts } from './model';

import './Facts.scss';

const Facts: FC<IFacts> = ({
  title,
  description,
  facts,
  sectionAnchor,
  colWidth = { md: 12 },
  anchorText,
  fileLink,
  sectionBg,
  sectionTheme,
}) => {
  const { relativePath } = fileLink?.gatsbyDocFile || {};
  const { label } = sectionBg?.[0]?.properties?.colorPicker || {};
  const isDarkTheme = ['navy', 'red', 'red-ribbon', 'amber-darken'].includes(label || '');

  return (
    <section
      className={classnames('facts', {
        [`${label}-default-bg`]: label,
        'facts--dark': isDarkTheme,
        [`${sectionTheme}`]: sectionTheme,
      })}
    >
      <Container fluid id={sectionAnchor}>
        <Row>
          <Col {...colWidth}>
            {title ? <h2 className="facts__title">{title}</h2> : null}
            <DangerouslySetInnerHtml className="facts__description" html={description} />
            {relativePath && anchorText ? (
              <a href={relativePath} className="facts__download-link" download>
                {anchorText}
              </a>
            ) : null}
            <ul className="facts__list">
              {facts.map(
                ({
                  properties: {
                    title: factTitle,
                    subtitle,
                    metric,
                    description: factDescription,
                    image,
                    imageAlt,
                  },
                }) => (
                  <li className="facts__list-item" key={metric}>
                    {image ? (
                      <div className="facts__item-image">
                        <UniversalImage img={image} imageAlt={imageAlt} />
                      </div>
                    ) : (
                      <>
                        {factTitle ? <p className="facts__item-title">{factTitle}</p> : null}
                        {subtitle ? <p className="facts__item-subtitle">{subtitle}</p> : null}
                        {metric ? <p className="facts__item-metric">{metric}</p> : null}
                        <DangerouslySetInnerHtml
                          html={factDescription}
                          className="facts__item-description"
                        />
                      </>
                    )}
                  </li>
                )
              )}
            </ul>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export const query = graphql`
  fragment FragmentsFacts on IFacts {
    title
    description
    sectionAnchor
    facts {
      properties {
        description
        metric
        subtitle
        title
        image {
          ...FragmentFluidSmallImage
        }
        imageAlt
      }
    }
    anchorText
    fileLink {
      gatsbyDocFile {
        relativePath
      }
    }
    sectionTheme
    sectionBg {
      properties {
        colorPicker {
          label
        }
      }
    }
  }
`;

export default Facts;

import React, { FC } from 'react';
import classNames from 'classnames';
import { graphql } from 'gatsby';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { formatAnchor } from 'gatsby-theme-nurofen/src/utils/helpers';
import bodyRenderer from 'gatsby-theme-nurofen/src/utils/bodyRenderer';
import ArticleAnchorBlock from 'gatsby-theme-nurofen/src/components/ArticleAnchorBlock';
import HighlightedLinks from 'gatsby-theme-nurofen/src/components/HighlightedLinks';
import OrderedList from 'gatsby-theme-nurofen/src/components/OrderedList';
import HighlightedList from 'gatsby-theme-nurofen/src/components/HighlightedList';
import GatsbyImage from 'gatsby-theme-nurofen/src/components/common/GatsbyImage';
import Video from 'gatsby-theme-nurofen/src/components/common/Video';
import NFAccordion from 'gatsby-theme-nurofen/src/components/common/NFAccordion';
import DownloadFile from 'gatsby-theme-nurofen/src/components/DownloadFile';
import { parseExploreComponent } from 'gatsby-theme-nurofen/src/templates/HubPage/parsers';
import { Container } from 'react-bootstrap';
import Table from 'gatsby-theme-nurofen/src/components/common/Table';

import Quote from 'gatsby-theme-nurofen/src/components/common/Quote';
import ReadReportSection from 'gatsby-theme-nurofen/src/components/ReadReportSection';
import PainBannerExtendTitle from 'gatsby-theme-nurofen/src/components/PainBannerExtendTitle';
import Facts from 'gatsby-theme-nurofen/src/components/Facts';
import parseVideo from 'gatsby-theme-nurofen/src/components/common/Video/parsers';

import { IArticleBodyProps } from 'gatsby-theme-nurofen/src/components/ArticleBodySection/model';
import Explore from '../ExploreOverride';

import 'gatsby-theme-nurofen/src/components/ArticleBodySection/ArticleBodySection.scss';


const elements: AppPageDataTypes.TBodyElements = {
  'Article Anchor Block': ({ text, anchorName }, keyId) => (
    <Container fluid key="ArticleBodySectionAnchorBlock">
      <Row key={keyId}>
        <Col lg={{ span: 8, offset: 2 }}>
          <ArticleAnchorBlock id={formatAnchor(anchorName)} content={text} />
        </Col>
      </Row>
    </Container>
  ),
  'Accordion Structure': ({ items }, keyId) => (
    <Container fluid>
      <Row key={keyId}>
        <Col lg={{ span: 8, offset: 2 }}>
          {items.map(({ properties }) => (
            <NFAccordion selectedIndex={-1} items={[{ ...properties }]} />
          ))}
        </Col>
      </Row>
    </Container>
  ),
  'Article Ordered List': ({ orderedList, title, limit, pointsColor }, keyId) => (
    <Container fluid key="ArticleBodySectionOrderedList">
      <Row key={keyId}>
        <Col lg={{ span: 8, offset: 2 }}>
          <OrderedList
            orderedList={orderedList}
            title={title}
            limit={limit}
            pointsColor={pointsColor}
          />
        </Col>
      </Row>
    </Container>
  ),
  'Article Highlighted Links': ({ title, list, lineColor }, keyId) => (
    <Container fluid key="ArticleBodySectionHighlightedLinks">
      <Row key={keyId}>
        <Col lg={{ span: 8, offset: 2 }}>
          <HighlightedLinks title={title} lineColour={lineColor} points={list} />
        </Col>
      </Row>
    </Container>
  ),
  'Featured Image': ({ featuredImage, imgAlt }, keyId) =>
    featuredImage?.gatsbyImage?.childImageSharp ? (
      <Container fluid key="ArticleBodySectionFeatureImage">
        <Row key={keyId}>
          <Col lg={{ span: 8, offset: 2 }}>
            <GatsbyImage
              className="featured-img"
              fluid={featuredImage.gatsbyImage.childImageSharp.fluid}
              alt={imgAlt}
            />
          </Col>
        </Row>
      </Container>
    ) : null,
  'Video with Bottom Accordion': ({ videoBlock, videoBottomAccordion }, keyId) => (
    <Container fluid key="ArticleBodySectionVideoBlock">
      <Row key={keyId}>
        <Col lg={{ span: 8, offset: 2 }}>
          <div className="video-block-with-bottom-accordion">
            <Video {...parseVideo(videoBlock[0].properties)} />
            {videoBottomAccordion?.length > 0 ? (
              <NFAccordion
                selectedIndex={-1}
                items={videoBottomAccordion[0].properties.items.map(({ properties }) => ({
                  ...properties,
                }))}
              />
            ) : null}
          </div>
        </Col>
      </Row>
    </Container>
  ),

  'Highlighted List': ({ title, highlightedList, image, imgAlt, pointsColor, position }, keyId) => (
    <Container fluid key="ArticleBodySectionHighlightedList">
      <Row key={keyId}>
        <Col lg={{ span: 8, offset: 2 }}>
          <HighlightedList
            title={title}
            list={highlightedList}
            img={image}
            imgPosition={position}
            imgAltText={imgAlt}
            pointsColor={pointsColor}
          />
        </Col>
      </Row>
    </Container>
  ),
  'Download file': ({ title, fileLink, anchorText, image }, keyId) => (
    <Row key={keyId}>
      <Col lg={{ span: 8, offset: 2 }}>
        <DownloadFile title={title} fileLink={fileLink} anchorText={anchorText} image={image} />
      </Col>
    </Row>
  ),
  Table: ({ table, keyId }) => (
    <Container fluid key="ArticleBodySectionTable">
      <Row key={keyId}>
        <Col lg={{ span: 8, offset: 2 }}>
          <Table content={table} />
        </Col>
      </Row>
    </Container>
  ),
  Quote: ({ quote, quoteBackgroundColor }, keyId) => (
    <div key="ArticleBodySectionQuote">
      <Row key={keyId}>
        <Col>
          <Quote content={quote} background={quoteBackgroundColor} />
        </Col>
      </Row>
    </div>
  ),
  Explore: (item, keyId) => {
    const itemParsedData = parseExploreComponent(item);
    return <Explore {...itemParsedData} key={keyId} />;
  },
  'Read Report Section': ({
    title,
    description,
    sectionAnchor,
    image,
    imageAlt,
    anchorText,
    fileLink,
    icon,
    sectionBackground,
  }) => (
    <ReadReportSection
      title={title}
      description={description}
      sectionAnchor={formatAnchor(sectionAnchor)}
      image={image}
      imageAlt={imageAlt}
      anchorText={anchorText}
      fileLink={fileLink}
      icon={icon}
      sectionBackground={sectionBackground}
    />
  ),
  'Pain Banner Extend Title': (
    { title, header, headerSmall, image, imageMobile, imageAlt, buttonLink, text, sectionTheme },
    keyId
  ) => (
    <PainBannerExtendTitle
      key={keyId}
      title={title}
      header={header}
      headerSmall={headerSmall}
      image={image}
      imageMobile={imageMobile}
      imageAlt={imageAlt}
      buttonLink={buttonLink}
      text={text}
      sectionTheme={sectionTheme}
    />
  ),
  'Facts Section': ({ title, facts, sectionAnchor }, keyId) => (
    <Facts title={title} facts={facts} sectionAnchor={formatAnchor(sectionAnchor)} key={keyId} />
  ),
};

const ArticleBodySection: FC<IArticleBodyProps> = ({ content, bulletColor, pageName }) => {
  const classList = classNames('article-body', {
    [`${bulletColor?.[0].properties.colorPicker?.label}-default-bg`]: bulletColor,
  });
  const articleBodySectionContent = bodyRenderer(content, elements, { pageName });

  return <div className={classList}>{articleBodySectionContent}</div>;
};

export const query = graphql`
  fragment FragmentArticleBodySection on IArticleBodySection {
    ...FragmentHighlightedList
    ...FragmentOrderedListI
    ...FragmentAccordionCommon
    ...FragmentDownloadFile
    ...FragmentsFacts
    sectionAnchor
    description
    anchorText
    fileLink {
      gatsbyDocFile {
        relativePath
      }
    }
    icon {
      svg {
        content
      }
    }
    sectionBackground {
      properties {
        colorPicker {
          label
        }
      }
    }
    imgAlt
    videoBlock {
      structure
      properties {
        ...FragmentVideoBlock
      }
    }
    videoBottomAccordion {
      structure
      properties {
        ...FragmentAccordionCommon
      }
    }
    featuredImage {
      fallbackUrl
      gatsbyImage {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    ...FragmentExplore
    text
    table
    quote
    quoteBackgroundColor {
      properties {
        colorPicker {
          label
        }
      }
    }
    anchorName
    title
    lineColor {
      properties {
        colorPicker {
          label
        }
      }
    }
    list {
      properties {
        text
      }
    }
    limit
    position
    pointsColor {
      ...FragmentColorProps
    }
    image {
      fallbackUrl
      gatsbyImage {
        childImageSharp {
          fluid(maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    imageAlt
  }
`;

export default ArticleBodySection;

import React, { FC, useEffect, useState } from 'react';
import sortBy from 'lodash.sortby';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import Container from 'react-bootstrap/Container';

import ProductsCarousel from 'gatsby-theme-nurofen/src/components/ProductsCarousel';
import { DEFAULT_PRODUCT_FAMILY_COLOR } from 'gatsby-theme-nurofen/src/utils/constants';
import { getProductDescription } from 'gatsby-theme-nurofen/src/utils/helpers';
import { gtmService } from 'gatsby-theme-nurofen/src/services/gtmService';

import ProductCardOverride from '../ProductCardOverride';

import { RelatedProductsProps } from './model';
import responsiveObj from './constants';

import 'gatsby-theme-nurofen/src/components/RelatedProducts/RelatedProducts.scss';
import './RelatedProductsOverride.scss';

const RelatedProductsOverride: FC<RelatedProductsProps> = ({
  title,
  subTitle,
  products,
  productsLinks,
  btn,
  showBtn = false,
  carouselControls,
  listingName,
}) => {
  const [productsList, setProductsList] = useState<ProductCard.IProductCard[]>(products);

  useEffect(() => {
    const productItems = products;

    if (productsLinks?.length) {
      const sortedProducts = sortBy(products, (item) => productsLinks?.indexOf(item.link));
      setProductsList(sortedProducts);
    } else {
      setProductsList(products);
    }
    const timeoutId = gtmService.emitProductListingView(listingName, productItems);

    return () => {
      timeoutId && clearTimeout(timeoutId);
    };
  }, [products, productsLinks]);

  return (
    <div className="related-products-wrapper" id="related-products-wrapper">
      <Container fluid>
        <ProductsCarousel
          title={title}
          subtitle={subTitle}
          responsiveObj={responsiveObj}
          carouselClass="related-product"
          btn={btn}
          {...carouselControls}
        >
          {productsList.map(
            ({
              sku,
              label,
              descriptionLong,
              descriptionShort,
              descriptionExtended,
              descriptionOverTheTitle,
              footnotes,
              defaultProductTitle,
              defaultProductImage,
              productLinks,
              productFamily,
              link,
              productImageAlt,
              productRelatedImage,
              productRelatedImageAlt,
            }) => (
              <ProductCardOverride
                key={sku}
                label={label}
                title={defaultProductTitle}
                descriptionExtended={descriptionExtended}
                descriptionOverTheTitle={descriptionOverTheTitle}
                text={getProductDescription(descriptionLong, descriptionShort)}
                img={defaultProductImage}
                productRelatedImage={productRelatedImage}
                productRelatedImageAlt={productRelatedImageAlt}
                classes={classNames('nf-related-product-card', {
                  'with-related-image': productRelatedImage,
                })}
                bg={
                  productFamily?.[0]?.relatedProductBg?.[0]?.properties.colorPicker?.label ||
                  DEFAULT_PRODUCT_FAMILY_COLOR
                }
                btn={productLinks?.[0]?.properties}
                shopBtnShow={showBtn}
                link={link}
                alt={productImageAlt}
                footnotes={footnotes}
              />
            )
          )}
        </ProductsCarousel>
      </Container>
    </div>
  );
};

export const query = graphql`
  fragment FragmentRelatedProductsConfig on IRelatedProductsConfig {
    relatedProductsTitle
    shopBtnsShow
    sectionCtaBtn {
      properties {
        ...FragmentNFButton
      }
    }
  }
`;

export default RelatedProductsOverride;

import React, { FC } from 'react';
import { graphql, Link } from 'gatsby';

import {
  LABEL_DARK_FONT_TEXT,
  LABEL_LIGHT_FONT_TEXT,
} from 'gatsby-theme-nurofen/src/utils/constants';

import UniversalImage from 'gatsby-theme-nurofen/src/components/common/UniversalImage';
import ProductLink from 'gatsby-theme-nurofen/src/components/ProductLink';
import DangerouslySetInnerHtml from 'gatsby-theme-nurofen/src/components/common/DangerouslySetInnerHtml';

import { ProductCardProps } from './model';
import 'gatsby-theme-nurofen/src/components/ProductCard/ProductCard.scss';
import './ProductCardOverride.scss';

const ProductCardOverride: FC<ProductCardProps> = ({
  label,
  labelBgColor,
  title,
  text,
  img,
  productRelatedImage,
  productRelatedImageAlt,
  classes,
  bg,
  shopBtnShow = true,
  btn,
  link,
  fontColor,
  showLabels = false,
  isLazyLoading,
  descriptionExtended,
  footnotes,
  descriptionOverTheTitle,
  alt,
  defaultButtontext = 'View product',
}) => (
  <div
    className={`card ${classes}`}
    style={
      bg &&
      ({
        backgroundColor: `var(--${bg})`,
      } as React.CSSProperties)
    }
  >
    {showLabels && label ? (
      <span
        className="nf-article-card__label"
        style={
          labelBgColor &&
          ({
            backgroundColor: `var(--${labelBgColor})`,
            color: `var(--${
              labelBgColor === 'navy' ? LABEL_LIGHT_FONT_TEXT : LABEL_DARK_FONT_TEXT
            })`,
          } as React.CSSProperties)
        }
      >
        {label}
      </span>
    ) : null}
    <div className="card-body">
      <div className="card-over-the-title">{descriptionOverTheTitle}</div>
      <div
        className="card-title"
        title={title}
        style={fontColor && ({ color: `var(--${fontColor})` } as React.CSSProperties)}
      >
        {title}
      </div>
      <div className="card-bulletlist">
        <DangerouslySetInnerHtml html={descriptionExtended || text} />
        {img?.gatsbyImage && (
          <UniversalImage
            img={img}
            imageAlt={alt}
            wrapperClassName="nf-card__img type--img"
            imgStyle={{ objectFit: 'contain' }}
            objectFitData={{ objectFit: 'contain' }}
            withDirectionClassName
            isLazyLoading={isLazyLoading}
          />
        )}
        {productRelatedImage?.gatsbyImage ? (
          <UniversalImage
            img={productRelatedImage}
            imageAlt={productRelatedImageAlt}
            wrapperClassName="nf-card__character-img"
            imgStyle={{ objectFit: 'contain' }}
            objectFitData={{
              objectFit: 'contain',
              objectPosition: '100% 100%',
            }}
          />
        ) : null}
      </div>
    </div>
    <Link className="nf-card-holder-link" to={link}>
      {defaultButtontext}
    </Link>
    {btn?.link?.[0]?.url && btn?.link?.[0]?.name ? (
      <ProductLink isHidden={!shopBtnShow} productLink={btn} />
    ) : null}

    <div className="footnotes">
      {footnotes ? <DangerouslySetInnerHtml html={footnotes} /> : null}
    </div>
  </div>
);

/**
 *  ATTENTION! Request should mirror /utils/algolia/productsList.js
 */
export const query = graphql`
  fragment FragmentProductCardOverride on IProductCard {
    sku
    link
    lang
    preferred {
      title
      id
    }
    descriptionLong
    descriptionShort
    descriptionExtended
    descriptionOverTheTitle
    footnotes
    defaultProductTitle
    defaultProductImage {
      fallbackUrl
      gatsbyImage {
        childImageSharp {
          fluid(maxHeight: 150, fit: CONTAIN, background: "rgba(0,0,0,0)") {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    productImageAlt
    productRelatedImage {
      fallbackUrl
      gatsbyImage {
        childImageSharp {
          fluid(maxWidth: 256, fit: CONTAIN, background: "rgba(0,0,0,0)") {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    productRelatedImageAlt
    productLinks {
      properties {
        ...FragmentProductLink
      }
    }
    productFamily {
      ...FragmentProductFamily
    }
    pageName
    productVariant
  }
`;

export default ProductCardOverride;
